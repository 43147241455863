<template>
  <div class="modal modal_wrap" @keyup.esc="closeWarning">
    <div class="modal_popup">
      <p class="text text_white"> {{ title }}
        <span class="modal__close" @click="closeWarning">
         <svg-icon name="times"/>
        </span>
      </p>
      <div class="justify-content-between">
        <slot name="actions" v-bind:discardChanges="closeSelectModal">
          <button class="btn btn_transparent" @click="closeWarning"><svg-icon name="solid/step-backward" /> Back</button>
          <button class="btn btn_transparent" @click="closeSelectModal"><svg-icon name="solid/check" /> Continue</button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>

  import router from "../../router";
  import {mapGetters} from 'vuex';

  export default {
    name: "WarningModal",
    props: {
      title: {
        type: String,
        default: 'Cancel will discard changes. Do want to continue?'
      }
    },
    methods: {
      closeSelectModal() {
        this.$emit('close');
        this.$store.commit('createdOrder/updateName', '');
        this.$store.commit('createdOrder/warningModal', false);
        this.$store.commit('createdOrder/cleanOrderObj');
        this.$store.commit('options/cleanOptions');
        this.$store.commit('options/setOldOptions', {});

        this.$store.commit('types/update', false);
        this.$store.commit('applySet/openModal', false);

        if (this.IsEditFromSet && !this.isEditOrderItem) {
          router.push({name: 'sets'});
          this.$store.commit('packages/setIsEditFromSet', false);
        }
        if (this.IsEditFromPackage && !this.isEditOrderItem) {
          router.push({name: 'packages'});
          this.$store.commit('packages/setIsEditFromPackage', false);
        }
        if (this.isEditOrderItem) {
          this.$store.commit('orderItem/set', {k: 'isUpdate', v: false});
        }
      },

      closeWarning() {
        this.$store.commit('createdOrder/warningModal', false);
      },

    },

    computed: {
      ...mapGetters(
        {
          IsEditFromSet: 'packages/IsEditFromSet',
          IsEditFromPackage: 'packages/IsEditFromPackage',
        }
      ),

      isEditOrderItem() {
          return this.$store.getters['orderItem/get']('isUpdate');
      }
    }
  }
</script>

<style scoped>
  .justify-content-between {
    display: flex;
  }

  p.text{
    position: relative;
    padding-right: 30px;
  }

  .modal__close{
    top: 0;
    right: 0;
  }

</style>
